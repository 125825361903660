
import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Users from './components/Users';
import Settings from './components/Settings';
import Calendar from './components/Calendar';
import SignIn from './components/Signin';
import SignUp from './components/Signup';
import Profile from './components/Profile';
import Logout from './components/Logout';
import Home from './components/Home';
import DashBoard from './components/DashBoard';
import Chat from './components/Chat';
import Jobform from './components/Jobform';
import Jobs from './components/Jobs';
import Updates from './components/Updates';
import Timesheet from './components/Timesheet';
import Administration from './components/Administration';
import { AuthProvider } from './components/AuthContext';
import PrivateRoute from "./components/Privateroute";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
        <Route path="/" element={<SignUp />} />

          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          

          <Route path="/" element={<Home />}>
            <Route element={<PrivateRoute />}>
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="users" element={<Users />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="timesheet" element={<Timesheet />} />
              <Route path="updates" element={<Updates />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="settings" element={<Settings />} />
              <Route path="jobs/add" element={<Jobform />} />
              <Route path="logout" element={<Logout />} />
              <Route path="chat" element={<Chat />} />
              <Route path="profile" element={<Profile />} />
              <Route path="administration" element={<Administration />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
