import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [openedJobs, setOpenedJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    filterJobs();
  }, [jobs, filter]);

  const fetchJobs = async () => {
    // Dummy data for demonstration purposes
    const dummyJobs = [
      { id: 1, jobName: 'Job 1', ownerName: 'Owner 1', location: 'Location 1', phoneNumber: '123-456-7890', open: true, startDate: '2023-01-01', endDate: '2023-01-10' },
      { id: 2, jobName: 'Job 2', ownerName: 'Owner 2', location: 'Location 2', phoneNumber: '123-456-7891', open: false, startDate: '2023-01-02', endDate: '2023-01-11' },
      { id: 3, jobName: 'Job 3', ownerName: 'Owner 3', location: 'Location 3', phoneNumber: '123-456-7892', open: true, startDate: '2023-01-03', endDate: '2023-01-12' },
      { id: 4, jobName: 'Job 4', ownerName: 'Owner 4', location: 'Location 4', phoneNumber: '123-456-7893', open: false, startDate: '2023-01-04', endDate: '2023-01-13' },
      { id: 5, jobName: 'Job 5', ownerName: 'Owner 5', location: 'Location 5', phoneNumber: '123-456-7894', open: true, startDate: '2023-01-05', endDate: '2023-01-14' },
      { id: 6, jobName: 'Job 6', ownerName: 'Owner 6', location: 'Location 6', phoneNumber: '123-456-7895', open: false, startDate: '2023-01-06', endDate: '2023-01-15' },
      { id: 7, jobName: 'Job 7', ownerName: 'Owner 7', location: 'Location 7', phoneNumber: '123-456-7896', open: true, startDate: '2023-01-07', endDate: '2023-01-16' },
      { id: 8, jobName: 'Job 8', ownerName: 'Owner 8', location: 'Location 8', phoneNumber: '123-456-7897', open: false, startDate: '2023-01-08', endDate: '2023-01-17' },
      { id: 9, jobName: 'Job 9', ownerName: 'Owner 9', location: 'Location 9', phoneNumber: '123-456-7898', open: true, startDate: '2023-01-09', endDate: '2023-01-18' },
      { id: 10, jobName: 'Job 10', ownerName: 'Owner 10', location: 'Location 10', phoneNumber: '123-456-7899', open: false, startDate: '2023-01-10', endDate: '2023-01-19' },
    ];

    setJobs(dummyJobs);
  };

  const filterJobs = () => {
    const opened = jobs.filter(job => job.open);
    const closed = jobs.filter(job => !job.open);
    setOpenedJobs(opened);
    setClosedJobs(closed);
  };

  const handleStatusClick = (id) => {
    const updatedJobs = jobs.map(job =>
      job.id === id ? { ...job, open: !job.open } : job
    );
    setJobs(updatedJobs);
  };

  const handleDeleteClick = (id) => {
    const updatedJobs = jobs.filter(job => job.id !== id);
    setJobs(updatedJobs);
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4 py-5">
      <div className="flex items-center justify-between mb-6">
        <h5 className="text-2xl font-bold text-gray-800">Jobs</h5>
        <div>
          <button
            className={`py-2 px-4 rounded shadow mr-2 ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-500 text-black'}`}
            onClick={() => setFilter('all')}
          >
            All Jobs
          </button>
          <button
            className={`py-2 px-4 rounded shadow mr-2 ${filter === 'open' ? 'bg-blue-500 text-white' : 'bg-gray-500 text-black'}`}
            onClick={() => setFilter('open')}
          >
            Opened Jobs
          </button>
          <button
            className={`py-2 px-4 rounded shadow mr-2 ${filter === 'closed' ? 'bg-red-500 text-white' : 'bg-gray-500 text-black'}`}
            onClick={() => setFilter('closed')}
          >
            Closed Jobs
          </button>
          <Link to="/jobs/add" className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded shadow">
            Add Job
          </Link>
        </div>
      </div>

      {filter === 'all' && (
        <div className="bg-white shadow-md rounded-lg overflow-hidden" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-blue-600 text-white uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left font-semibold">Job</th>
                <th className="py-3 px-6 text-left font-semibold">Owner</th>
                <th className="py-3 px-6 text-left font-semibold">Location</th>
                <th className="py-3 px-6 text-left font-semibold">Phone No</th>
                <th className="py-3 px-6 text-left font-semibold">Status</th>
                <th className="py-3 px-6 text-left font-semibold">Start Date</th>
                <th className="py-3 px-6 text-left font-semibold">End Date</th>
                <th className="py-3 px-6 text-center font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              {currentJobs.map((job) => (
                <tr key={job.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-4 px-6 text-left whitespace-nowrap">{job.jobName}</td>
                  <td className="py-4 px-6 text-left">{job.ownerName}</td>
                  <td className="py-4 px-6 text-left">{job.location}</td>
                  <td className="py-4 px-6 text-left">{job.phoneNumber}</td>
                  <td className="py-4 px-6 text-left">
                    <span
                      className={`py-1 px-3 rounded-full text-xs font-semibold ${job.open ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}
                    >
                      {job.open ? 'Open' : 'Closed'}
                    </span>
                  </td>
                  <td className="py-4 px-6 text-left">{new Date(job.startDate).toLocaleDateString()}</td>
                  <td className="py-4 px-6 text-left">{new Date(job.endDate).toLocaleDateString()}</td>
                  <td className="py-4 px-6 text-center">
                    <div className="flex justify-center items-center">
                      <button
                        className="text-blue-600 hover:text-blue-900 mr-4"
                        onClick={() => handleStatusClick(job.id)}
                      >
                        Status
                      </button>
                      <button
                        className="text-red-600 hover:text-red-900"
                        onClick={() => handleDeleteClick(job.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {filter === 'open' && (
        <div className="bg-white shadow-md rounded-lg overflow-hidden" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-green-500 text-white uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left font-semibold">Job</th>
                <th className="py-3 px-6 text-left font-semibold">Owner</th>
                <th className="py-3 px-6 text-left font-semibold">Location</th>
                <th className="py-3 px-6 text-left font-semibold">Phone No</th>
                <th className="py-3 px-6 text-left font-semibold">Status</th>
                <th className="py-3 px-6 text-left font-semibold">Start Date</th>
                <th className="py-3 px-6 text-left font-semibold">End Date</th>
                <th className="py-3 px-6 text-center font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              {openedJobs.map((job) => (
                <tr key={job.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-4 px-6 text-left whitespace-nowrap">{job.jobName}</td>
                  <td className="py-4 px-6 text-left">{job.ownerName}</td>
                  <td className="py-4 px-6 text-left">{job.location}</td>
                  <td className="py-4 px-6 text-left">{job.phoneNumber}</td>
                  <td className="py-4 px-6 text-left">
                    <span className="py-1 px-3 rounded-full text-xs font-semibold bg-green-200 text-green-800">
                      Open
                    </span>
                  </td>
                  <td className="py-4 px-6 text-left">{new Date(job.startDate).toLocaleDateString()}</td>
                  <td className="py-4 px-6 text-left">{new Date(job.endDate).toLocaleDateString()}</td>
                  <td className="py-4 px-6 text-center">
                    <div className="flex justify-center items-center">
                      <button
                        className="text-blue-600 hover:text-blue-900 mr-4"
                        onClick={() => handleStatusClick(job.id)}
                      >
                      
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {filter === 'closed' && (
         <div className="bg-white shadow-md rounded-lg overflow-hidden" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-red-500 text-white uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left font-semibold">Job</th>
                <th className="py-3 px-6 text-left font-semibold">Owner</th>
                <th className="py-3 px-6 text-left font-semibold">Location</th>
                <th className="py-3 px-6 text-left font-semibold">Phone No</th>
                <th className="py-3 px-6 text-left font-semibold">Status</th>
                <th className="py-3 px-6 text-left font-semibold">Start Date</th>
                <th className="py-3 px-6 text-left font-semibold">End Date</th>
                <th className="py-3 px-6 text-center font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              {closedJobs.map((job) => (
                <tr key={job.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-4 px-6 text-left whitespace-nowrap">{job.jobName}</td>
                  <td className="py-4 px-6 text-left">{job.ownerName}</td>
                  <td className="py-4 px-6 text-left">{job.location}</td>
                  <td className="py-4 px-6 text-left">{job.phoneNumber}</td>
                  <td className="py-4 px-6 text-left">
                    <span className="py-1 px-3 rounded-full text-xs font-semibold bg-red-200 text-red-800">
                      Closed
                    </span>
                  </td>
                  <td className="py-4 px-6 text-left">{new Date(job.startDate).toLocaleDateString()}</td>
                  <td className="py-4 px-6 text-left">{new Date(job.endDate).toLocaleDateString()}</td>
                  <td className="py-4 px-6 text-center">
                    <div className="flex justify-center items-center">
                      <button
                        className="text-blue-600 hover:text-blue-900 mr-4"
                        onClick={() => handleStatusClick(job.id)}
                      >
                     
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-center mt-4">
        {Array.from({ length: Math.ceil(jobs.length / jobsPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={`py-2 px-4 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Jobs;
