import React from 'react';
import {
  HomeIcon,
  UserGroupIcon,
  BriefcaseIcon,
  CalendarIcon,
  ShieldCheckIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  ChatBubbleBottomCenterTextIcon,
  ClockIcon,
} from '@heroicons/react/24/solid';
import { useNavigate, Outlet } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-1/3 lg:w-1/5 border-r shadow-lg bg-gray-50 text-black flex flex-col overflow-y-auto">
        <div className="flex items-center p-8 border-b border-gray-300 bg-gray-100">
          <img
            src="https://cdn.pixabay.com/photo/2016/05/19/17/09/woman-1403418_640.jpg"
            alt="Admin Profile"
            className="w-12 h-12 rounded-full cursor-pointer"
            onClick={() => navigate('/profile')}
          />
          <span className="ml-3 text-xl font-bold">Admin Name</span>
        </div>
        <nav className="flex flex-col flex-grow space-y-1">
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/dashboard')}
          >
            <HomeIcon className="w-6 h-6 mr-3" />
            Dashboard
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/users')}
          >
            <UserGroupIcon className="w-6 h-6 mr-3" />
            Users
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/jobs')}
          >
            <BriefcaseIcon className="w-6 h-6 mr-3" />
            Jobs
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/calendar')}
          >
            <CalendarIcon className="w-6 h-6 mr-3" />
            Calendar
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/updates')}
          >
            <UserCircleIcon className="w-6 h-6 mr-3" />
            Updates
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/timesheet')}
          >
            <ClockIcon className="w-6 h-6 mr-3" />
            Timesheet
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/chat')}
          >
            <ChatBubbleBottomCenterTextIcon className="w-6 h-6 mr-3" />
            Chat
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300"
            onClick={() => navigate('/administration')}
          >
            <ShieldCheckIcon className="w-6 h-6 mr-3" />
            Administration
          </a>
          <a
            href="#"
            className="flex items-center py-4 px-8 hover:bg-blue-200 transition ease-in-out duration-300 bg-gray-50"
            onClick={() => navigate('/signup')}
          >
            <ArrowRightOnRectangleIcon className="w-6 h-6 mr-3" />
            Logout
          </a>
        </nav>
      </div>
      <div className="w-2/3 lg:w-4/5 bg-white p-5 text-black flex flex-col overflow-y-auto">
        <div className="flex-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
