import React, { useState, useEffect } from 'react';
import axios from 'axios';


const dummyUsers = [
  { id: 1, name: 'John Doe', email: 'john.doe@example.com', phone: '123-456-7890', location: 'New York', active: true },
  { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', phone: '987-654-3210', location: 'Los Angeles', active: false },
  { id: 3, name: 'Mike Johnson', email: 'mike.johnson@example.com', phone: '555-123-4567', location: 'Chicago', active: true },
  { id: 4, name: 'Emily Davis', email: 'emily.davis@example.com', phone: '111-222-3333', location: 'San Francisco', active: false },
  { id: 5, name: 'Chris Brown', email: 'chris.brown@example.com', phone: '444-555-6666', location: 'Miami', active: true },
  { id: 6, name: 'Jessica Wilson', email: 'jessica.wilson@example.com', phone: '777-888-9999', location: 'Seattle', active: false },
  { id: 7, name: 'David Moore', email: 'david.moore@example.com', phone: '222-333-4444', location: 'Boston', active: true },
  { id: 8, name: 'Sarah Taylor', email: 'sarah.taylor@example.com', phone: '666-777-8888', location: 'Dallas', active: false },
  { id: 9, name: 'Kevin Martinez', email: 'kevin.martinez@example.com', phone: '999-000-1111', location: 'Houston', active: true },
  { id: 10, name: 'Amanda Clark', email: 'amanda.clark@example.com', phone: '333-444-5555', location: 'Austin', active: false },
  { id: 11, name: 'Michael Scott', email: 'michael.scott@example.com', phone: '777-888-9999', location: 'Scranton', active: true },
  { id: 12, name: 'Pam Beesly', email: 'pam.beesly@example.com', phone: '222-333-4444', location: 'Scranton', active: false },
  { id: 13, name: 'Jim Halpert', email: 'jim.halpert@example.com', phone: '666-777-8888', location: 'Scranton', active: true },
  { id: 14, name: 'Dwight Schrute', email: 'dwight.schrute@example.com', phone: '999-000-1111', location: 'Scranton', active: false },
  { id: 15, name: 'Angela Martin', email: 'angela.martin@example.com', phone: '333-444-5555', location: 'Scranton', active: true }
];

const Users = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); 
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    // Simulating API call with dummy data
    setUsers(dummyUsers);
  }, []);

  useEffect(() => {
    const results = users.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results);
  }, [searchTerm, users]);

  const handleStatusClick = (id) => {
    const updatedUsers = users.map(user =>
      user.id === id ? { ...user, active: !user.active } : user
    );
    setUsers(updatedUsers);
  };

  const handleDeleteClick = (id) => {
    const updatedUsers = users.filter(user => user.id !== id);
    setUsers(updatedUsers);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const results = users.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results);
  };

  return (
    <div className="container mx-auto px-4 py-5">
      <div className="mb-6 flex justify-between items-center">
        <h5 className="text-2xl font-bold text-gray-800">Users</h5>
        <div className="flex">
          <input
            type="text"
            className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button
            className="py-2 px-4 bg-blue-500 text-white rounded-lg ml-2 hover:bg-blue-600"
            onClick={handleSearchSubmit}
          >
            Search
          </button>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-blue-600 text-white uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left font-semibold">Name</th>
                <th className="py-3 px-6 text-left font-semibold">Email</th>
                <th className="py-3 px-6 text-left font-semibold">Phone</th>
                <th className="py-3 px-6 text-left font-semibold">Location</th>
                <th className="py-3 px-6 text-left font-semibold">Status</th>
                <th className="py-3 px-6 text-center font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              {currentUsers.map((user) => (
                <tr key={user.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-4 px-6 text-left whitespace-nowrap">{user.name}</td>
                  <td className="py-4 px-6 text-left">{user.email}</td>
                  <td className="py-4 px-6 text-left">{user.phone}</td>
                  <td className="py-4 px-6 text-left">{user.location}</td>
                  <td className="py-4 px-6 text-left">
                    <span
                      className={`py-1 px-3 rounded-full text-xs font-semibold ${user.active ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}
                    >
                      {user.active ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td className="py-4 px-6 text-center">
                    <div className="flex justify-center items-center">
                      <button
                        className="text-blue-600 hover:text-blue-900 mr-4"
                        onClick={() => handleStatusClick(user.id)}
                      >
                        Status
                      </button>
                      <button
                        className="text-red-600 hover:text-red-900"
                        onClick={() => handleDeleteClick(user.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-center mt-4">
        {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={`py-2 px-4 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Users;
