import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [profileDetails, setProfileDetails] = useState({
        
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleSave = async () => {
        setLoading(true);
        setError(null);
        try {
            
            await new Promise(resolve => setTimeout(resolve, 1000));

            console.log("Saved changes:", profileDetails);
            setIsEditing(false);
        } catch (error) {
            setError('Failed to save changes');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    return (
        <div className="flex flex-col md:flex-row justify-center items-center h-screen bg-white p-4 md:p-8">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl mb-4 md:mb-0 md:mr-4">
                <div className="flex items-center mb-6">
                    <img
                        src="https://cdn.pixabay.com/photo/2016/05/19/17/09/woman-1403418_640.jpg"
                        alt="Admin Profile"
                        className="w-16 h-16 rounded-full"
                    />
                    <div className="ml-4">
                        <h2 className="text-xl font-bold">{profileDetails.name}</h2>
                        <p className="text-gray-700">{profileDetails.email}</p>
                    </div>
                </div>
                <div className="flex flex-col space-y-4">
                    <button
                        className="flex items-center p-4 border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300"
                        onClick={() => navigate('/profile')}
                    >
                        <svg className="w-6 h-6 mr-3 text-purple-600" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                        </svg>
                        My Profile
                    </button>
                    <button
                        className="flex items-center p-4 border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300"
                        onClick={() => navigate('/settings')}
                    >
                        <svg className="w-6 h-6 mr-3 text-purple-600" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M19.14 12.94c.04-.32.07-.65.07-.98s-.03-.66-.07-.98l2.03-1.58c.18-.14.22-.41.1-.61l-1.92-3.32c-.12-.21-.39-.29-.61-.21l-2.39 1c-.5-.38-1.05-.7-1.65-.94l-.36-2.65c-.04-.23-.23-.4-.47-.4h-3.78c-.24 0-.43.17-.47.4l-.36 2.65c-.6.24-1.15.56-1.65.94l-2.39-1c-.22-.09-.49 0-.61.21l-1.92 3.32c-.12.21-.08.47.1.61l2.03 1.58c-.04.32-.07.65-.07.98s.03.66.07.98l-2.03 1.58c-.18.14-.22.41-.1.61l1.92 3.32c.12.21.39.29.61.21l2.39-1c.5.38 1.05.7 1.65.94l.36 2.65c.04.23.23.4.47.4h3.78c.24 0 .43-.17.47-.4l.36-2.65c.6-.24 1.15-.56 1.65-.94l2.39 1c.22.09.49 0 .61-.21l1.92-3.32c.12-.21.08-.47-.1-.61l-2.03-1.58zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
                        </svg>
                        Settings
                    </button>
                    <button
                        className="flex items-center p-4 border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300"
                        onClick={() => navigate('/notifications')}
                    >
                        <svg className="w-6 h-6 mr-3 text-purple-600" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-2.97-2.16-5.43-5-5.91V4c0-.83-.67-1.5-1.5-1.5S10 3.17 10 4v1.09c-2.84.48-5 2.94-5 5.91v5l-1.29 1.29c-.19.19-.3.45-.3.71v.01c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-.01c0-.26-.11-.52-.29-.71L18 16z" />
                        </svg>
                        Notification
                    </button>
                    <button
                        className="flex items-center p-4 border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300"
                        onClick={() => navigate('/logout')}
                    >
                        <svg className="w-6 h-6 mr-3 text-purple-600" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M16 13v-2h-4V8l-5 4 5 4v-3h4zM2 2v20h14v-2H4V4h12V2H2z" />
                        </svg>
                        Log Out
                    </button>
                </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl md:ml-4">
                <h2 className="text-xl font-bold mb-6">Profile Details</h2>
                <div className="space-y-4">
                    <div className="flex items-center">
                        <span className="w-1/3 font-medium">Name:</span>
                        {isEditing ? (
                            <input
                                type="text"
                                name="name"
                                value={profileDetails.name}
                                onChange={handleInputChange}
                                className="w-2/3 p-2 border border-gray-300 rounded-lg"
                            />
                        ) : (
                            <span className="w-2/3">{profileDetails.name}</span>
                        )}
                    </div>
                    <div className="flex items-center">
                        <span className="w-1/3 font-medium">Email account:</span>
                        {isEditing ? (
                            <input
                                type="email"
                                name="email"
                                value={profileDetails.email}
                                onChange={handleInputChange}
                                className="w-2/3 p-2 border border-gray-300 rounded-lg"
                            />
                        ) : (
                            <span className="w-2/3">{profileDetails.email}</span>
                        )}
                    </div>
                    <div className="flex items-center">
                        <span className="w-1/3 font-medium">Mobile number:</span>
                        {isEditing ? (
                            <input
                                type="tel"
                                name="mobile"
                                value={profileDetails.mobile}
                                onChange={handleInputChange}
                                className="w-2/3 p-2 border border-gray-300 rounded-lg"
                            />
                        ) : (
                            <span className="w-2/3">{profileDetails.mobile}</span>
                        )}
                    </div>
                    <div className="flex items-center">
                        <span className="w-1/3 font-medium">Location:</span>
                        {isEditing ? (
                            <input
                                type="text"
                                name="location"
                                value={profileDetails.location}
                                onChange={handleInputChange}
                                className="w-2/3 p-2 border border-gray-300 rounded-lg"
                            />
                        ) : (
                            <span className="w-2/3">{profileDetails.location}</span>
                        )}
                    </div>
                    <div className="flex items-center space-x-4">
                        {isEditing ? (
                            <>
                                <button
                                    className="mt-6 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                                    onClick={handleSave}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save Changes'}
                                </button>
                                <button
                                    className="mt-6 py-2 px-4 bg-gray-300 text-white rounded-lg hover:bg-gray-400 transition duration-300"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <button
                                className="mt-6 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                                onClick={() => setIsEditing(true)}
                            >
                                Edit
                            </button>
                        )}
                    </div>
                    {error && <p className="text-red-500 mt-4">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default Profile;
