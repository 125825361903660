import React, { useEffect, useState } from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import axios from 'axios';

const Dashboard = () => {
  const [data, setData] = useState({
    activeFarmers: { active: 0, total: 0 },
    questionsAnswered: 0,
    jobsPosted: 0,
    revenue: 0,
    activeUsers: 0,
    orders: 0,
    revenueData: [],
    activeUsersData: [],
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/dashboard');
      const fetchedData = response.data;
      setData({
        activeFarmers: { active: fetchedData.activeFarmers.active, total: fetchedData.activeFarmers.total },
        questionsAnswered: fetchedData.questionsAnswered,
        jobsPosted: fetchedData.jobsPosted,
        revenue: fetchedData.revenue,
        activeUsers: fetchedData.activeUsers,
        orders: fetchedData.orders,
        revenueData: fetchedData.revenueData,
        activeUsersData: fetchedData.activeUsersData,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const revenueData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Monthly Revenue',
        data: data.revenueData,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const activeUsersData = {
    labels: ['Morning', 'Afternoon', 'Evening'],
    datasets: [
      {
        label: 'Active Users',
        data: data.activeUsersData,
        backgroundColor: ['#4caf50', '#ff9800', '#f44336'],
        hoverBackgroundColor: ['#388e3c', '#fb8c00', '#e53935'],
      },
    ],
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-auto overflow-y-auto p-4">
        <div className="max-w-7xl mx-auto bg-white p-8 shadow-lg rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <div className="flex flex-col items-center justify-center border p-6 rounded-lg shadow-md text-center bg-green-100">
              <p className="font-bold text-lg mb-2">Active Farmers</p>
              <p className="text-2xl text-green-500">{`${data.activeFarmers.active}/${data.activeFarmers.total}`}</p>
            </div>
            <div className="flex flex-col items-center justify-center border p-6 rounded-lg shadow-md text-center bg-pink-100">
              <p className="font-bold text-lg mb-2">Questions Answered</p>
              <p className="text-2xl text-blue-500">{data.questionsAnswered}</p>
            </div>
            <div className="flex flex-col items-center justify-center border p-6 rounded-lg shadow-md text-center bg-violet-100">
              <p className="font-bold text-lg mb-2">Jobs Posted</p>
              <p className="text-2xl text-purple-500">{data.jobsPosted}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="border p-6 rounded-lg shadow-md bg-gray-50">
              <p className="font-bold text-lg mb-2">Revenue</p>
              <p className="text-2xl text-gray-700 mb-4">IDR {data.revenue.toLocaleString()}</p>
              <div className="h-64">
                <Line data={revenueData} options={{ maintainAspectRatio: false }} />
              </div>
            </div>
            <div className="border p-6 rounded-lg shadow-md bg-rose-50">
              <p className="font-bold text-lg mb-2">Active Users</p>
              {data.activeUsersData.length > 0 ? (
                <React.Fragment>
                  <p className="text-2xl text-gray-700 mb-4">{data.activeUsers} active users</p>
                  <div className="h-64">
                    <Doughnut data={activeUsersData} options={{ maintainAspectRatio: false }} />
                  </div>
                </React.Fragment>
              ) : (
                <p className="text-lg text-gray-500">No active user data available</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="border p-6 rounded-lg shadow-md bg-fuchsia-100">
              <p className="font-bold text-lg mb-2">Jobs Posted</p>
              {}
            </div>
            <div className="border p-6 rounded-lg shadow-md bg-yellow-100">
              <p className="font-bold text-lg mb-2">Most Asked Questions</p>
              {}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="border p-6 rounded-lg shadow-md bg-indigo-100">
              <p className="font-bold text-lg mb-2">Orders</p>
              <p className="text-2xl text-red-500">{data.orders}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

/*import React, { useEffect, useState } from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import axios from 'axios';

const Dashboard = () => {
  const [data, setData] = useState({
    activeFarmers: { active: 120, total: 150 },
    questionsAnswered: 350,
    jobsPosted: 78,
    revenue: 250000000,
    activeUsers: 550,
    orders: 280,
    revenueData: [12000000, 15000000, 18000000, 14000000, 16000000, 20000000, 19000000, 22000000, 21000000, 23000000, 24000000, 26000000],
    activeUsersData: [150, 250, 150],
  });

  useEffect(() => {
    
  }, []);

  const revenueData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Monthly Revenue',
        data: data.revenueData,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const activeUsersData = {
    labels: ['Morning', 'Afternoon', 'Evening'],
    datasets: [
      {
        label: 'Active Users',
        data: data.activeUsersData,
        backgroundColor: ['#4caf50', '#ff9800', '#f44336'],
        hoverBackgroundColor: ['#388e3c', '#fb8c00', '#e53935'],
      },
    ],
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-auto overflow-y-auto p-4">
        <div className="max-w-7xl mx-auto bg-white p-8 shadow-lg rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <div className="flex flex-col items-center justify-center border p-6 rounded-lg shadow-md text-center bg-green-100">
              <p className="font-bold text-lg mb-2">Active Farmers</p>
              <p className="text-2xl text-green-500">{`${data.activeFarmers.active}/${data.activeFarmers.total}`}</p>
            </div>
            <div className="flex flex-col items-center justify-center border p-6 rounded-lg shadow-md text-center bg-pink-100">
              <p className="font-bold text-lg mb-2">Questions Answered</p>
              <p className="text-2xl text-blue-500">{data.questionsAnswered}</p>
            </div>
            <div className="flex flex-col items-center justify-center border p-6 rounded-lg shadow-md text-center bg-violet-100">
              <p className="font-bold text-lg mb-2">Jobs Posted</p>
              <p className="text-2xl text-purple-500">{data.jobsPosted}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="border p-6 rounded-lg shadow-md bg-gray-50">
              <p className="font-bold text-lg mb-2">Revenue</p>
              <p className="text-2xl text-gray-700 mb-4">IDR {data.revenue.toLocaleString()}</p>
              <div className="h-64">
                <Line data={revenueData} options={{ maintainAspectRatio: false }} />
              </div>
            </div>
            <div className="border p-6 rounded-lg shadow-md bg-rose-50">
              <p className="font-bold text-lg mb-2">Active Users</p>
              {data.activeUsersData.length > 0 ? (
                <React.Fragment>
                  <p className="text-2xl text-gray-700 mb-4">{data.activeUsers} active users</p>
                  <div className="h-64">
                    <Doughnut data={activeUsersData} options={{ maintainAspectRatio: false }} />
                  </div>
                </React.Fragment>
              ) : (
                <p className="text-lg text-gray-500">No active user data available</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="border p-6 rounded-lg shadow-md bg-fuchsia-100">
              <p className="font-bold text-lg mb-2">Jobs Posted</p>
              {}
            </div>
            <div className="border p-6 rounded-lg shadow-md bg-yellow-100">
              <p className="font-bold text-lg mb-2">Most Asked Questions</p>
              {}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="border p-6 rounded-lg shadow-md bg-indigo-100">
              <p className="font-bold text-lg mb-2">Orders</p>
              <p className="text-2xl text-red-500">{data.orders}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );x
};

export default Dashboard;
*/
