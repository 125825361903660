import React, { useState } from 'react';
import axios from 'axios';

const JobForm = () => {
    const [job, setJob] = useState({
        ownerName: '',
        phoneNumber: '',
        location: '',
        startDate: '',
        endDate: '',
        jobName: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setJob((prevJob) => ({
            ...prevJob,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5454/jobs/save', job);
            alert('Successfully added');
            
            //console.log('Job created successfully:', response.data);
        } catch (error) {
            console.error('There was an error creating the job:', error);
        }
    };

    return (
        <div className='max-w-2xl border shadow-lg rounded-lg p-6 mx-auto bg-white'>
            <h2 className='text-2xl font-bold mb-4 text-center'>Job Form</h2>
            <form onSubmit={handleSubmit}>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>Owner Name</label>
                    <input 
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
                        type='text' 
                        name='ownerName'
                        value={job.ownerName}
                        onChange={handleChange}
                        placeholder='Owner Name' 
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>Phone Number</label>
                    <input 
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
                        type='tel' 
                        name='phoneNumber'
                        value={job.phoneNumber}
                        onChange={handleChange}
                        placeholder='Phone Number' 
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>Location</label>
                    <input 
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
                        type='text' 
                        name='location'
                        value={job.location}
                        onChange={handleChange}
                        placeholder='Location' 
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>Start Date</label>
                    <input 
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
                        type='date' 
                        name='startDate'
                        value={job.startDate}
                        onChange={handleChange}
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>End Date</label>
                    <input 
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
                        type='date' 
                        name='endDate'
                        value={job.endDate}
                        onChange={handleChange}
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>Job</label>
                    <input 
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
                        type='text' 
                        name='jobName'
                        value={job.jobName}
                        onChange={handleChange}
                        placeholder='Job Description' 
                    />
                </div>
                <div className='text-center'>
                    <button 
                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' 
                        type='submit'
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default JobForm;
